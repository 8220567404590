import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')

// Users
const Search = () => import("@/views/Search")
const CreateNewInvoice = () => import("@/views/CreateNewInvoice")
const CompanyInfo = () => import("@/views/CompanyInfo")
const Customer = () => import("@/views/Customer")
const Currency = () => import("@/views/Currency")
const PayItem = () => import("@/views/PayItem")
const Login = () => import("@/views/pages/Login")
Vue.use(Router)

export default new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: 'search',
          name: 'Search',
          component: Search
        },
        {
          path: 'createNewInvoice',
          name: 'CreateNewInvoice',
          component: CreateNewInvoice
        },
        {
          path: 'companyInfo',
          name: 'CompanyInfo',
          component: CompanyInfo
        },
        {
          path: 'customer',
          name: 'Customer',
          component: Customer
        },
        {
          path: 'currency',
          name: 'Currency',
          component: Currency
        },
        {
          path: 'payItem',
          name: 'payItem',
          component: PayItem
        },
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: 'modifyInvoice/:invoiceId',
          name: 'modifyInvoice',
          component: CreateNewInvoice
        },
        
        

        
      ]
    },
    
  ]
}

