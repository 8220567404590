<template>
  <router-view></router-view>
</template>

<script>

import firebase from 'firebase/app';
import 'firebase/auth';

const firebaseConfig = {
apiKey: "AIzaSyBCF9MnFKnO8q2b6Eu4OXxVvTQUF6ZUl8k",
authDomain: "luciditv-happiness-admin.firebaseapp.com",
databaseURL: "https://luciditv-happiness-admin.firebaseio.com",
projectId: "luciditv-happiness-admin",
storageBucket: "luciditv-happiness-admin.appspot.com",
messagingSenderId: "215939208462",
appId: "1:215939208462:web:c91370825a7fa9469bf35f"
};

firebase.initializeApp(firebaseConfig);
export default {
  name: 'App',

  created () {
  const self = this;
  firebase.auth().onAuthStateChanged(function(user) {
  if (user) {
    if(self.$route.name === 'Login') {
      self.$router.replace('/');
    }
  // ...
  } else {
   // User is signed out.
  // ...
  }
  });
  }

 }
</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';
</style>
